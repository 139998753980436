import React from 'react';
import ContactInfo from '../ContactInfo/ContactInfo';

import AboutMe from '../AboutMe/AboutMe';
import Education from '../Education/Education';
import WorkExperience from '../WorkExperience/WorkExperience';
import './ResumeContainer.css';


const ResumeContainer = ({
                             email,
                             linkedin_username,
                             linkedin_url,
                             name,
                             designation,
                             education,
                             about_me,
                             experience,
                             phone,
                             achievements,
                         }) => {
    return (
        <div className="container">
            <div className="leftPanel">
                <img src={`${process.env.PUBLIC_URL}/mitali.png`} alt="Profile"/>
                <div className="details">
                    <ContactInfo
                        email={email}
                        linkedin_username={linkedin_username}
                        linkedin_url={linkedin_url}
                        phone={phone}
                    />
                    <Education education={education}/>
                </div>
            </div>
            <div className="rightPanel">
                <h1>{name}</h1>
                <h3>{designation}</h3>
                <AboutMe about_me={about_me}/>
                <WorkExperience experience={experience}/>
            </div>
        </div>
    );
};

export default ResumeContainer;
