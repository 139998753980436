import React from 'react';

const Education = ({ education }) => {
  return (
    <div className="item">
      <h2>EDUCATION</h2>
      <div className="smallText">
        {education.map((edu, index) => (
          <div className="edu-degree" key={index}>
            <p className="bolded white">{edu.degree}</p>
            <p>{edu.institute}</p>
            <p>
              {edu.start_year} {edu.end_year ? ` - ${edu.end_year}` : ''}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Education;
